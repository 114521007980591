import { WalletListItem } from './WalletListItem'
import { Wallet } from 'crypto/interface'

interface WalletListProps {
  wallets: Wallet[]
  selectedWallet: Wallet | undefined
  onSelectedWallet: (wallet: Wallet) => void
}

export function WalletList({
  wallets,
  selectedWallet,
  onSelectedWallet,
}: WalletListProps) {
  return (
    <div>
      {wallets.map((wallet) => (
        <WalletListItem
          key={wallet.id}
          wallet={wallet}
          isActive={wallet.id === selectedWallet?.id}
          onClick={() => onSelectedWallet(wallet)}
        />
      ))}
    </div>
  )
}
