import * as S from './styles'
import { Asset, AssetType, ChainType, WalletId } from 'crypto/interface'
import React, { useCallback, useMemo } from 'react'
import { Dropdown } from 'ui/Common/components/Dropdown'
import { Menu } from 'ui/Common/components/Menu'
import { MenuItemProp } from 'ui/Common/components/Menu/Menu'
import {
  NotificationType,
  useNotifications,
} from 'ui/Common/components/Notifications'
import ThreeDotsVert from 'ui/Icons/components/ThreeDotsVert'

const MenuItemKey = {
  AddToMetamask: 'AddMadaToMetamask',
}

// -- Prop types
// ----------

export interface AssetItemActionMenuProps {
  walletId: WalletId | undefined
  asset: Asset
  addCustomToken?: (
    walletId: WalletId,
    chain: ChainType,
    assetId: AssetType
  ) => Promise<boolean>
}

// -- Component
// ----------

/** Asset item action menu */
const AssetItemActionMenu: React.VFC<AssetItemActionMenuProps> = (props) => {
  const { addNotification } = useNotifications()

  const handleActionMenuClick = useCallback(
    (key: string) => {
      if (key === MenuItemKey.AddToMetamask) {
        if (props.walletId) {
          props
            .addCustomToken?.(props.walletId, props.asset.chain, props.asset.id)
            .then(() => {
              // metamask promise resolves as soon as the wallet is opened and before user has approved new token
              // hence, there is no point in showing success notification which will even be covered by the wallet window
            })
            .catch((err) => {
              addNotification({
                content: `Error adding "${props.asset.ticker}" token to "${props.walletId}" wallet: ${err}`,
                type: NotificationType.Error,
              })
            })
        }
      }
    },
    [props, addNotification]
  )

  const assetItemActions = useMemo(() => {
    const items: MenuItemProp[] = []
    // --- menu items
    // add mADA to Metamask
    if (props.asset.chain === 'polygon' && props.asset.ticker === 'mADA') {
      items.push({
        key: MenuItemKey.AddToMetamask,
        label: 'Add to Metamask',
      })
    }

    return items
  }, [props.asset.chain, props.asset.ticker])

  return assetItemActions.length > 0 ? (
    <Dropdown
      placement="bottom-end"
      overlay={
        <Menu items={assetItemActions} onClick={handleActionMenuClick} />
      }
    >
      <S.ActionMenuButton>
        <ThreeDotsVert />
      </S.ActionMenuButton>
    </Dropdown>
  ) : null
}

export { AssetItemActionMenu }
