import styled from 'styled-components'
import Box from 'ui/Common/components/Box'

export const InfoBox = styled(Box)`
  // align text content
  > :not(svg) {
    align-self: center;
  }

  // align icon section
  > :first-child {
    align-self: flex-start;
  }
`
