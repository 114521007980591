import { AssetListHeader } from './AssetListHeader'
import AssetListItem, { VestingListItem } from './AssetListItem'
import { AssetList as AssetListStyles } from './styles'
import BigNumber from 'bignumber.js'
import {
  Asset,
  AssetType,
  ChainType,
  VestingData,
  WalletId,
} from 'crypto/interface'
import orderBy from 'lodash/orderBy'
import React, { useCallback, useState } from 'react'
import { EmptyListMessage } from 'ui/Common/components/EmptyListMessage'

type AssetListProps = {
  vestingAddress?: string
  walletIsPending: boolean
  walletId?: WalletId
  assets: Asset[]
  vestingData?: VestingData
  addCustomToken?: (
    walletId: WalletId,
    chain: ChainType,
    assetId: AssetType
  ) => Promise<boolean>
}

export default function AssetList({
  vestingAddress,
  walletIsPending,
  walletId,
  assets,
  vestingData,
  addCustomToken,
}: AssetListProps) {
  const [sortField, setSortField] = useState('')

  const handleSortClick = useCallback((field: string) => {
    setSortField((oldSortField) => {
      if (oldSortField === field) {
        return `-${field}`
      }

      if (oldSortField === `-${field}`) {
        return ''
      }

      return field
    })
  }, [])

  const sortInfo = sortField.split('-')
  const sortDirection = sortInfo[0] === '' ? 'asc' : 'desc'
  const sortColumnName = sortInfo[0] === '' ? sortInfo[1] : sortInfo[0]

  function compare(a: BigNumber | undefined, b: BigNumber | undefined) {
    if (a && b) return a.comparedTo(b) // both are BigNumber
    if (a === b) return 0 // both are undefined
    return a ? 1 : -1 // a or b are undefined
  }

  const sortedAssets =
    sortColumnName === 'fiatBalance'
      ? assets.sort((a, b) => {
          const cmp = compare(a.fiatBalance, b.fiatBalance)
          return sortDirection === 'asc' ? cmp : -cmp
        })
      : orderBy(assets, [sortColumnName], [sortDirection])

  return (
    <>
      <AssetListHeader sortField={sortField} onSortChange={handleSortClick} />
      <AssetListStyles>
        {vestingData && vestingAddress && walletId && (
          <VestingListItem
            address={vestingAddress}
            walletId={walletId}
            walletIsPending={walletIsPending}
            rewardIndex={11}
            totalAmount={vestingData.totalAmount}
            pendingAmount={vestingData.pendingAmount}
            stakedAmount={vestingData.stakedAmount}
            withdrawingAmount={vestingData.withdrawingAmount}
          />
        )}

        {sortedAssets.length > 0 &&
          sortedAssets.map((asset) => (
            <AssetListItem
              key={walletId + asset.chain + asset.id}
              walletId={walletId}
              asset={asset}
              addCustomToken={addCustomToken}
            />
          ))}

        {sortedAssets.length === 0 && (
          <EmptyListMessage message="Asset list is empty" />
        )}
      </AssetListStyles>
    </>
  )
}
