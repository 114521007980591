import React, { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import * as S from 'ui/Common/components/Dropdown/styles'

// -- Prop types
// ----------

export interface DropdownOverlayProps {
  overlay: React.ReactNode
  opened?: boolean
  containerRef?: React.ForwardedRef<HTMLDivElement>
}

// -- Component
// ----------

/** Rendering container for given dropdown element. */
const DropdownOverlay: React.FC<PropsWithChildren<DropdownOverlayProps>> = (
  props
) => {
  return createPortal(
    <S.OverlayContainer isOpened={props.opened} ref={props.containerRef}>
      {props.overlay}
    </S.OverlayContainer>,
    document.body
  )
}

export { DropdownOverlay as DropdownOveray }
