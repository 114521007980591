import * as S from './styles'
import {
  Asset,
  VestingData,
  Wallet,
  LinkResult,
  LinkWalletStatus,
  WalletId,
  AssetType,
  ChainType,
} from 'crypto/interface'
import React, { useMemo } from 'react'
import Box from 'ui/Common/components/Box'
import { Grid, Row, Column } from 'ui/Common/components/Grid'
import Text from 'ui/Common/components/Text'
import { supportedWalletIds } from 'ui/Common/config'
import { AddWallet } from 'ui/CreateWallet/components/AddWallet'
import AssetList from 'ui/Wallet/components/AssetList/AssetList'
import { WalletList } from 'ui/Wallet/components/WalletList/WalletList'

function WalletsHeader() {
  return (
    <S.ListHeader walletHeader={true}>
      <Box>Wallets</Box>
      <Box>Balance</Box>
    </S.ListHeader>
  )
}

export function WalletContainer({
  wallets,
  selectedWallet,
  onSelectedWallet,
  linkMetamask,
  linkNami,
  linkEternl,
  metamaskStatus,
  namiStatus,
  eternlStatus,
  isWalletConnected,
  vestingData,
  allAssets,
  addCustomToken,
}: {
  wallets: Wallet[]
  selectedWallet: Wallet | undefined
  onSelectedWallet: (wallet: Wallet) => void
  linkMetamask: () => Promise<LinkResult>
  linkNami: () => Promise<LinkResult>
  linkEternl: () => Promise<LinkResult>
  metamaskStatus: LinkWalletStatus
  namiStatus: LinkWalletStatus
  eternlStatus: LinkWalletStatus
  isWalletConnected: (walletId: WalletId) => boolean
  vestingData: VestingData | undefined
  allAssets: Asset[]
  addCustomToken?: (
    walletId: WalletId,
    chain: ChainType,
    assetId: AssetType
  ) => Promise<boolean>
}) {
  // has any connected wallets
  const hasWallets = !!wallets.length

  // are all supported wallets already connected
  const allWalletsConnected = useMemo(() => {
    return supportedWalletIds.every(isWalletConnected)
  }, [isWalletConnected])

  const walletAssets = useMemo(() => {
    return (
      (selectedWallet?.assets ?? [])
        // filter only assets with balance >0
        .filter((asset) => asset.unitBalance.gt(0))
    )
  }, [selectedWallet])

  return (
    <Grid>
      <Row>
        <Column base={12}>
          {wallets.length ? (
            <>
              <Text
                style={{ marginTop: 64 }}
                block
                color="grey.grey10"
                fontSize={96}
                fontWeight={700}
                lh={1}
              >
                My wallets
              </Text>
              <Text block mt={24} color="grey.grey6" variant="paragraph.1">
                More wallets. More freedom. Easy.
              </Text>
            </>
          ) : (
            <>
              <Text
                style={{ marginTop: 64 }}
                block
                color="grey.grey10"
                fontSize={96}
                fontWeight={700}
                lh={1}
              >
                No wallets
              </Text>
              <Text
                block
                color="grey.grey10"
                fontSize={96}
                fontWeight={700}
                lh={1}
              >
                connected yet
              </Text>
              <Text block mt={32} color="grey.grey10" variant="paragraph.1">
                Select action
              </Text>
            </>
          )}
        </Column>
      </Row>

      <Row>
      <Text block mt={24} color="red.red6" variant="paragraph.1">
          The MELD token has an ongoing migration to a new version of the token. You can read all about the migration <a style={{color: '#FD003D'}} target="_blank" href="https://docs.meld.com/meld-token/meld-token" >here</a>.
          <br/>The tokens you withdraw below will need to be migrated, you can do so in <a style={{color: '#FD003D'}} target="_blank" href="https://app.meld.com" >app.meld.com</a>.
          <br/>Please be aware that non-migrated tokens are <strong><u>NOT SUPPORTED</u></strong> on centralised exchanges. Always check the token policy ID before transferring to any protocol.
        </Text>
      </Row>

      <Row style={{ marginTop: 56, marginBottom: 32 }}>
        <Column base={5} style={{ marginTop: 48 }}>
          {hasWallets && <WalletsHeader />}
          <Box>
            <WalletList
              wallets={wallets}
              selectedWallet={selectedWallet}
              onSelectedWallet={onSelectedWallet}
            />
          </Box>

          {!allWalletsConnected && (
            <AddWallet
              linkMetamask={linkMetamask}
              linkNami={linkNami}
              linkEternl={linkEternl}
              metamaskStatus={metamaskStatus}
              namiStatus={namiStatus}
              eternlStatus={eternlStatus}
            />
          )}
        </Column>

        <Column base={6} offset={1} style={{ marginTop: 48 }}>
          {selectedWallet ? (
            <AssetList
              vestingAddress={selectedWallet.addresses.cardano}
              walletIsPending={selectedWallet.isPending}
              walletId={selectedWallet.id}
              assets={walletAssets}
              vestingData={vestingData}
              addCustomToken={addCustomToken}
            />
          ) : (
            <AssetList
              walletIsPending={false}
              assets={allAssets}
              vestingData={vestingData}
            />
          )}
        </Column>
      </Row>
    </Grid>
  )
}
