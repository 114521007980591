import * as S from './styles'
import React from 'react'
import Box from 'ui/Common/components/Box'

export function AssetListHeader({
  sortField,
  onSortChange,
}: {
  sortField: string
  onSortChange: (field: string) => void
}) {
  return (
    <S.HeaderWrapper>
      <Box d={'flex'}>
        <S.ChainsWrapper>
          <S.HeaderItem>Chain</S.HeaderItem>
        </S.ChainsWrapper>

        <S.AssetsWrapper
          style={{ cursor: 'pointer' }}
          onClick={() => onSortChange('name')}
        >
          <S.HeaderItem>Asset</S.HeaderItem>
          <S.SortIcon
            $highlightUp={sortField === '-name'}
            $highlightDown={sortField === 'name'}
          />
        </S.AssetsWrapper>
      </Box>

      <S.BalanceWrapper
        style={{ cursor: 'pointer' }}
        onClick={() => onSortChange('fiatBalance')}
      >
        <S.HeaderItem>Balance</S.HeaderItem>
        <S.SortIcon
          $highlightUp={sortField === '-fiatBalance'}
          $highlightDown={sortField === 'fiatBalance'}
        />
      </S.BalanceWrapper>
    </S.HeaderWrapper>
  )
}
