import * as S from './styles'
import React from 'react'
import Box from 'ui/Common/components/Box'
import Text from 'ui/Common/components/Text'

/**
 * InfoBox public interface
 */
type InfoBoxProps = {
  /**
   * Textual content of the InfoBox.
   */
  children: React.ReactNode
  /**
   * Icon renderd before InfoBox text.
   */
  icon?: React.ReactElement<React.SVGProps<SVGSVGElement>>
  /**
   * Override container styling using inline styles.
   */
  style: React.CSSProperties
  /**
   * Override icon container styling using inline styles.
   */
  iconContainerStyle?: React.CSSProperties
}

/**
 * InfoBox component
 */
function InfoBox(props: InfoBoxProps) {
  return (
    <S.InfoBox
      p={36}
      br={8}
      bg="grey.grey8"
      d="inline-flex"
      w="100%"
      style={props.style}
    >
      {/* ICON SECTION */}
      {props.icon && (
        <Box mr={16} style={props.iconContainerStyle}>
          {props.icon}
        </Box>
      )}
      {/* TEXT SECTION */}
      <Text variant="paragraph.4" color="#fff">
        {props.children}
      </Text>
    </S.InfoBox>
  )
}

InfoBox.defaultProps = {
  style: {},
}

export default InfoBox
