import LinkWallet from '../LinkWallet'
import * as S from './styles'
import { LinkResult, LinkWalletStatus } from 'crypto/interface'
import { useRouter } from 'next/router'
import { memo, useState } from 'react'
import Box from 'ui/Common/components/Box'
import { Button, ButtonVariant } from 'ui/Common/components/Button'
import { Popup } from 'ui/Common/components/Popup'
import Text from 'ui/Common/components/Text'

function _AddWallet({
  linkMetamask,
  linkNami,
  linkEternl,
  metamaskStatus,
  namiStatus,
  eternlStatus,
}: {
  linkMetamask: () => Promise<LinkResult>
  linkNami: () => Promise<LinkResult>
  linkEternl: () => Promise<LinkResult>
  metamaskStatus: LinkWalletStatus
  namiStatus: LinkWalletStatus
  eternlStatus: LinkWalletStatus
}) {
  const [linkPopupOpen, setLinkPopupOpen] = useState(false)
  const router = useRouter()

  return (
    <>
      <S.Wrapper>
        <Text variant="heading.6" color="grey.grey6">
          ADD WALLET
        </Text>
        <Box d="flex" style={{ gap: 8 }}>
          {
            //TODO: not used currently
            false && (
              <>
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={() => router.push('/wallet/create')}
                >
                  Create
                </Button>
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={() => router.push('/wallet/restore')}
                >
                  Restore
                </Button>
              </>
            )
          }
          <Button
            variant={ButtonVariant.Secondary}
            onClick={() => setLinkPopupOpen(true)}
          >
            Link
          </Button>
        </Box>
      </S.Wrapper>
      {linkPopupOpen && (
        <Popup onClose={() => setLinkPopupOpen(false)}>
          <LinkWallet
            linkMetamask={linkMetamask}
            linkNami={linkNami}
            linkEternl={linkEternl}
            metamaskStatus={metamaskStatus}
            namiStatus={namiStatus}
            eternlStatus={eternlStatus}
            onSuccess={() => setLinkPopupOpen(false)}
          />
        </Popup>
      )}
    </>
  )
}

export const AddWallet = memo(_AddWallet)
