import styled, { css } from 'styled-components'

export const DropdownContainer = styled.div`
  display: inline-block;
`

export const OverlayContainer = styled.div<{ isOpened?: boolean }>`
  position: absolute;
  display: none;
  opacity: 0;
  z-index: 10; // keep select list above subsequent content

  background-color: ${(p) => p.theme.colors.white.white100};
  border-radius: ${(p) => p.theme.container.borderRadius.s}px;

  box-shadow: 0px 1px 32px 0px rgba(0, 0, 0, 0.15);

  transition: opacity 0.2s ease-in-out;
  ${(props) =>
    (props.isOpened ?? false) &&
    css`
      display: block;
      opacity: 1;
    `}
`
