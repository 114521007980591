import styled from 'styled-components'

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${(p) => p.theme.colors.white.white100};
`

export const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${(p) => p.theme.spacing.xs}px ${(p) => p.theme.spacing.s}px;
  flex-wrap: nowrap;

  background-color: ${(p) => p.theme.colors.white.white100};

  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey.grey3};
  }
`
