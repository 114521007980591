import FiatBalance from '../Grpahs/FiatBalance'
import ProgressBar from './ProgressBar'
import * as S from './styles'
import BigNumber from 'bignumber.js'
import { Asset, AssetType, ChainType, WalletId } from 'crypto/interface'
import { useRouter } from 'next/router'
import React, { useRef } from 'react'
import { AnimatedSpinnerIcon } from 'ui/Common/components/Animation/AnimatedSpinnerIcon'
import AssetLogo from 'ui/Common/components/AssetLogo'
import AssetStats from 'ui/Common/components/AssetStats'
import { Button, ButtonVariant } from 'ui/Common/components/Button'
import { ChainDisplay } from 'ui/Common/components/ChainDisplay'
import { formatBalanceAmount } from 'ui/Common/util/format/amountFormat'
import { VestingLockIcon } from 'ui/Icons/components/VestingLockIcon'
import { AssetItemActionMenu } from 'ui/Wallet/components/AssetList/AssetItemActionMenu'

export default function AssetListItem({
  walletId,
  asset,
  addCustomToken,
}: {
  walletId: WalletId | undefined
  asset: Asset
  addCustomToken?: (
    walletId: WalletId,
    chain: ChainType,
    assetId: AssetType
  ) => Promise<boolean>
}) {
  const router = useRouter()
  const isEmpty = asset.unitBalance.isZero()

  /*** Flashing logic start ***/
  // marks last known state of `asset.isPending`
  const pendingCache = useRef(asset.isPending)
  // ensure no flash on mount
  const isFlashing = useRef(false)
  // If asset is currently pending, ensure it's not flashing
  if (asset.isPending) {
    isFlashing.current = false
  } else {
    // asset is not pending, was it pending before this update?
    if (pendingCache.current) {
      isFlashing.current = true
    }
  }
  // update the cache to current value
  pendingCache.current = asset.isPending
  /*** Flashing logic end ***/

  return (
    <S.Wrapper initial={isEmpty} isFlashing={isFlashing.current}>
      <S.ChainSection>
        <ChainDisplay chain={asset.chain} toggleName={true} />
      </S.ChainSection>
      <S.NameSection>
        <AssetLogo assetId={asset.id} chain={asset.chain} />
        {asset.name}
      </S.NameSection>
      <S.BalanceSection hasGraph={asset.hasGraph}>
        <S.Amount
          hasSpinner={asset.isPending}
          hasGraph={!!asset.fiatBalanceHistory}
          isEmpty={isEmpty}
        >
          {formatBalanceAmount(asset.unitBalance)}
          <AnimatedSpinnerIcon />
          <div className="assetstats">
            <AssetStats
              fiatBalance={asset.fiatBalance}
              unitPrice={asset.unitPrice}
              change={asset.change}
              isPending={asset.isPending}
            />
          </div>
        </S.Amount>
        {isEmpty && (walletId != null || asset.id === 'meld') && (
          <S.ItemSwitcher singleItem>
            <S.ButtonsWrapper>
              <Button
                variant={ButtonVariant.Secondary}
                onClick={() => {
                  if (asset.id === 'meld') {
                    router.push(`https://meld.com/how-to-buy-meld`)
                  } else {
                    router.push(
                      `/wallet/receive?wallet=${walletId}&chain=${asset.chain}&asset=${asset.id}`
                    )
                  }
                }}
              >
                {asset.id === 'meld' ? 'Buy' : 'Receive'}
              </Button>
            </S.ButtonsWrapper>
          </S.ItemSwitcher>
        )}
        {!isEmpty && walletId != null && (
          <S.ItemSwitcher singleItem={asset.fiatBalanceHistory ? false : true}>
            {asset.fiatBalanceHistory && (
              <div className="graph">
                <FiatBalance points={asset.fiatBalanceHistory} />
              </div>
            )}

            <S.ButtonsWrapper>
              {walletId !== 'eternl' && (
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={() =>
                    router.push(
                      `/wallet/stats?wallet=${walletId}&chain=${asset.chain}&asset=${asset.id}`
                    )
                  }
                >
                  Stats and activity
                </Button>
              )}
              <Button
                variant={ButtonVariant.Secondary}
                onClick={() =>
                  router.push(
                    `/wallet/receive?wallet=${walletId}&chain=${asset.chain}&asset=${asset.id}`
                  )
                }
              >
                Receive
              </Button>
              <Button
                variant={ButtonVariant.Secondary}
                disabled={asset.isPending}
                onClick={() =>
                  router.push(
                    `/wallet/send?wallet=${walletId}&chain=${asset.chain}&asset=${asset.id}`
                  )
                }
              >
                Send
              </Button>

              <AssetItemActionMenu
                walletId={walletId}
                asset={asset}
                addCustomToken={addCustomToken}
              />
            </S.ButtonsWrapper>
          </S.ItemSwitcher>
        )}
      </S.BalanceSection>
    </S.Wrapper>
  )
}

export interface VestingListItemProps {
  address: string
  walletId: WalletId
  walletIsPending: boolean
  rewardIndex: number
  totalAmount: BigNumber
  pendingAmount: BigNumber
  stakedAmount: BigNumber
  withdrawingAmount: BigNumber | undefined
}

export function VestingListItem({
  address,
  walletId,
  walletIsPending,
  rewardIndex,
  totalAmount,
  pendingAmount,
  stakedAmount,
  withdrawingAmount,
}: VestingListItemProps) {
  const router = useRouter()
  const totalProgressPeriods = 25
  const percentVested = new BigNumber('1').minus(pendingAmount.div(totalAmount))
  const activeProgressPeriods = Math.floor(
    percentVested.multipliedBy(totalProgressPeriods).toNumber()
  )
  const totalBalance = pendingAmount.plus(stakedAmount)

  /*** Flashing logic start ***/
  // marks last known state of `withdrawing
  const pendingCache = useRef(!!withdrawingAmount)
  // ensure no flash on mount
  const isFlashing = useRef(false)
  // If withdrawing is currently pending, ensure it's not flashing
  if (withdrawingAmount) {
    isFlashing.current = false
  } else {
    // withdrawing is not pending, was it pending before this update?
    if (pendingCache.current) {
      isFlashing.current = true
    }
  }
  // update the cache to current value
  pendingCache.current = !!withdrawingAmount
  /*** Flashing logic end ***/

  const isWithdrawingOrPending = walletIsPending || !!withdrawingAmount
  const canWithdraw = !isWithdrawingOrPending && !stakedAmount.isZero()

  return (
    <S.VestingWrapper isFlashing={isFlashing.current}>
      <S.NameSection>
        <VestingLockIcon />
        <S.ProgressBarWrapper>
          VESTING MELD
          <ProgressBar
            active={activeProgressPeriods}
            total={totalProgressPeriods}
            rewardIndex={rewardIndex}
          />
        </S.ProgressBarWrapper>
      </S.NameSection>
      <S.BalanceSection>
        <S.Amount>
          {formatBalanceAmount(totalBalance)}
          <S.VestingStats withdrawing={isWithdrawingOrPending}>
            {withdrawingAmount && (
              <>
                <S.Withdrawing>
                  WITHDRAWING{' '}
                  <span>{formatBalanceAmount(withdrawingAmount)}</span>
                </S.Withdrawing>
                |
              </>
            )}
            <S.Available>
              AVAILABLE <span>{formatBalanceAmount(stakedAmount)}</span>
            </S.Available>
            <AnimatedSpinnerIcon />
          </S.VestingStats>
        </S.Amount>
        <S.VestingControlsWrapper withdrawing={isWithdrawingOrPending}>
          <Button
            variant={ButtonVariant.Secondary}
            disabled={!canWithdraw}
            onClick={() =>
              router.push(
                `/vesting/withdraw?wallet=${walletId}&address=${address}`
              )
            }
          >
            Withdraw
          </Button>
        </S.VestingControlsWrapper>
      </S.BalanceSection>
    </S.VestingWrapper>
  )
}
