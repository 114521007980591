import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DropdownOveray } from 'ui/Common/components/Dropdown/DropdownOverlay'
import * as S from 'ui/Common/components/Dropdown/styles'
import {
  ElementPlacement,
  useElementPlacement,
} from 'ui/Common/util/hooks/useElementPlacement'

type DropdownTriggerType = 'click'
// TODO: add support for 'hover' trigger event

// -- Prop types
// ----------

export interface DropdownProps {
  overlay: React.ReactNode
  placement?: ElementPlacement
  trigger?: DropdownTriggerType
  disabled?: boolean
}

// -- Component
// ----------

/** Generic dropdown element which "drops" andy given overlay on trigger */
const Dropdown: React.FC<PropsWithChildren<DropdownProps>> = (props) => {
  const placement = props.placement ?? 'bottom-start'

  // is component opened
  const [opened, setOpened] = useState<boolean>(false)
  // dropdown container ref
  const containerRef = useRef<HTMLDivElement>(null)
  // overlay ref
  const overlayRef = useRef<HTMLDivElement>(null)

  // init "outside" click listeners - used for detecting when component looses focus
  useEffect(() => {
    // callback function (no need to create it outside because it's only ever used here)
    function handleOutsideClick(evt: any) {
      if (!containerRef?.current?.contains(evt.target as Node)) {
        setOpened(false) // close list
      }
    }

    // disabled - ignore all events
    if (props.disabled) return

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [props.disabled])

  /**
   * Position overlay to trigger container
   *
   * TODO: this could be a separate hook?
   */
  const placeElements = useElementPlacement()

  // sync overlay position with trigger
  useEffect(() => {
    if (opened) {
      if (containerRef.current && overlayRef.current) {
        placeElements(containerRef.current, overlayRef.current, placement)
      } else {
        console.warn(
          'Cannot place empty element',
          containerRef.current,
          overlayRef.current
        )
      }
    }
  }, [opened, placeElements, placement])

  // ---------- event handlers

  /** Handle button click */
  const handleClick = useCallback(() => {
    // disabled - ignore all events
    if (props.disabled) return

    setOpened(!opened) // toggle list
  }, [opened, props.disabled])

  const getEventHandler = useCallback(
    (eventType: DropdownTriggerType) => {
      if (props.trigger == null || props.trigger == eventType) {
        return handleClick
      }
    },
    [handleClick, props.trigger]
  )

  return (
    <S.DropdownContainer onClick={getEventHandler('click')} ref={containerRef}>
      {props.children}
      {opened && (
        <DropdownOveray
          overlay={props.overlay}
          opened={opened}
          containerRef={overlayRef}
        />
      )}
    </S.DropdownContainer>
  )
}

export { Dropdown }
