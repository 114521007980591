import React from 'react'

export const VestingLockIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 32}
    height={props.height || 32}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
      <path
        d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0z"
        fill="#FD003D"
        transform="translate(-1084 -2435) translate(1036 2379) translate(48 56)"
      />
      <g fill="#FFF">
        <path
          d="M11.889 5.556H4.11c-.92 0-1.667.746-1.667 1.666v4.445c0 .92.747 1.666 1.667 1.666h7.778c.92 0 1.667-.746 1.667-1.666V7.222c0-.92-.747-1.666-1.667-1.666zm-3.889 5a1.111 1.111 0 110-2.223 1.111 1.111 0 010 2.223z"
          transform="translate(-1084 -2435) translate(1036 2379) translate(48 56) translate(8 8) translate(.222 1.333)"
        />
        <path
          d="M5.368 2.42a2.2 2.2 0 00-1.985-1.309h-.024a2.21 2.21 0 00-2.248 2.173v1.16H0V3.278A3.312 3.312 0 013.305 0h.034a3.322 3.322 0 013.204 2.422l-1.175-.001z"
          transform="translate(-1084 -2435) translate(1036 2379) translate(48 56) translate(8 8) translate(.222 1.333) matrix(-1 0 0 1 6.543 0)"
        />
      </g>
    </g>
  </svg>
)
