import styled from 'styled-components'
import { LARGE_BREAKPOINT } from 'ui/Common/components/Grid'

export const ListHeader = styled.div<{ walletHeader?: boolean }>`
  height: 40px;
  padding: ${(p) => (p.walletHeader ? '0 48px' : '0 48px 0 16px')};
  border-radius: 8px;
  background-color: #d3d5da;

  color: #6c727f;
  font-size: 10px;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${LARGE_BREAKPOINT}px) {
    padding: ${(p) => (p.walletHeader ? '0 36px' : '0 36px 0 16px')};
  }

  > div,
  > * > div {
    cursor: pointer;
  }
`
