import { useState } from 'react'
import Box from 'ui/Common/components/Box'
import InfoBox from 'ui/Common/components/InfoBox'
import { Popup } from 'ui/Common/components/Popup'
import Text from 'ui/Common/components/Text'
import { BrowserIcon } from 'ui/Icons/components/BrowserIcon'

function SupportedBrowsers({
  showBrowserWarning,
}: {
  showBrowserWarning: boolean
}) {
  const [isOpen, setIsOpen] = useState(showBrowserWarning)

  if (!isOpen) return null

  return (
    <Popup onClose={() => setIsOpen(false)}>
      <Box
        w={496}
        h={536}
        d="flex"
        bg="white.white100"
        style={{
          justifyContent: 'center',
        }}
      >
        <Box
          d="flex"
          p="50px 8px 8px 8px"
          style={{ flexDirection: 'column', alignItems: 'center' }}
        >
          <InfoBox
            style={{ padding: 24 }}
            icon={<BrowserIcon width={20} height={20} />}
          >
            Note: Some wallets might not be supported in the current browser you
            are using. Please use one of the following browsers for optimal user
            experience.
          </InfoBox>

          <Box mt={48} mb={24}>
            <img alt="nami" width={92} height={92} src="/images/nami.png" />
          </Box>

          <Text mb={36} color="grey.grey10" variant="heading.2">
            Nami is available on
          </Text>
          <Box
            mb={4}
            w={210}
            d="flex"
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Box style={{ textAlign: 'center' }}>
              <img
                alt="chrome"
                width={40}
                height={40}
                src="/images/chrome.png"
              />
              <Text block color="grey.grey10" variant="paragraph.2">
                Chrome
              </Text>
            </Box>

            <Box>
              <img alt="brave" width={40} height={40} src="/images/brave.png" />
              <Text block color="grey.grey10" variant="paragraph.2">
                Brave
              </Text>
            </Box>

            <Box>
              <img alt="edge" width={40} height={40} src="/images/edge.png" />
              <Text block color="grey.grey10" variant="paragraph.2">
                Edge
              </Text>
            </Box>
          </Box>
          <Text mb={54} color="#6c727f" variant="paragraph.3">
            and any other chrome-based browser
          </Text>
        </Box>
      </Box>
    </Popup>
  )
}

export default SupportedBrowsers
