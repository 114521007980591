import styled from 'styled-components'
import { LARGE_BREAKPOINT } from 'ui/Common/components/Grid'

export const Wrapper = styled.div`
  height: 88px;
  position: relative;
  border-radius: 8px;
  display: flex;
  padding: 32px 48px;
  background-color: ${(p) => p.theme.colors.white.white100};
  margin-top: 4px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${LARGE_BREAKPOINT}px) {
    height: 76px;
    padding: 24px 36px;
  }
`
