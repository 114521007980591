import React, { useCallback } from 'react'
import * as S from 'ui/Common/components/Menu/styles'

export type MenuItemProp = {
  label: React.ReactNode
  key: string
}

// -- Prop types
// ----------

export interface MenuProps {
  items: MenuItemProp[]

  onClick?: (key: string) => void
}

// -- Component
// ----------

/** Menu component that can be used for lists and navigation */
const Menu: React.VFC<MenuProps> = (props) => {
  const handleItemClick = useCallback(
    (key: string) => () => {
      props.onClick?.(key)
    },
    [props.onClick]
  )

  return (
    <S.MenuContainer role="listbox">
      {props.items.map((item) => (
        <S.MenuItemContainer
          key={item.key}
          role="option"
          onClick={handleItemClick(item.key)}
        >
          {item.label}
        </S.MenuItemContainer>
      ))}
    </S.MenuContainer>
  )
}

export { Menu }
