import * as S from './styles'
import { FontWeight } from 'app/styles/typography'
import React from 'react'
import Text from 'ui/Common/components/Text'
import Tooltip from 'ui/Common/components/Tooltip/Tooltip'
import { BorrowIcon } from 'ui/Icons/components/BorrowIcon'
import { LockIcon } from 'ui/Icons/components/LockIcon'

export interface ProgressBarProps {
  active: number
  total: number
  rewardIndex: number
}

export default function ProgressBar({
  active,
  total,
  rewardIndex,
}: ProgressBarProps) {
  return (
    <S.ProgressBar rewardIndex={rewardIndex}>
      {/* <LockIcon width={12} height={12} fill={'#000'} /> */}

      <S.RewardIcon>
        <Tooltip
          multiline={true}
          text={
            <Text variant="paragraph.4" color="grey.grey10">
              <Text fontWeight={FontWeight.Bold}>Bonus?</Text> You’ll get 12%
              more if you hold MELD tokens until Dec 8th 2022.
            </Text>
          }
        >
          <BorrowIcon fill={'#68d04f'} />
        </Tooltip>
      </S.RewardIcon>

      {[...Array(total)].map((e, i) => (
        <S.ProgressBarItem
          active={i < active}
          isBorrow={rewardIndex === i + 1}
          key={i}
        />
      ))}
    </S.ProgressBar>
  )
}
