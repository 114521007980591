import React from 'react'

export const BorrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 20}
    height={props.height || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      fill={props.fill || '#000'}
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    >
      <path
        d="M14.05 11.24h-2.318c.116.462.179.936.187 1.413a.702.702 0 01-.703.694H4.917v-1.405h5.62a2.81 2.81 0 00-2.81-2.81h-2.81C4.074 7.93 2.322 7.727 1.475 7.727H0V13.7l5.755 2.643a3.079 3.079 0 002.282.188l8.823-2.714s-.556-2.576-2.81-2.576zM10.537 0a4.215 4.215 0 100 8.43 4.215 4.215 0 000-8.43zm.703 6.322H9.835V2.107h1.405v4.215z"
        transform="translate(-1149 -499) translate(908 376) translate(0 44) translate(101 79) translate(140) translate(1.667 1.667) matrix(1 0 0 -1 0 16.667) matrix(1 0 0 -1 0 16.667)"
      />
    </g>
  </svg>
)
