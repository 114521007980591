import { WalletContainer } from 'app/containers/Wallet'
import {
  useAllAssets,
  useLinkEternl,
  useLinkMetamask,
  useLinkNami,
  useMeldState,
  useAddCustomToken,
  useVestingData,
  useSelectedWallet,
} from 'crypto/hooks'
import Layout from 'ui/Common/components/Layout'
import SupportedBrowsers from 'ui/Wallet/components/SupportedBrowsers'

let IS_FIRST_PASS = true
const IS_BROWSER = typeof window !== 'undefined'

const isDesktop = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return false
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return false
  }
  return true
}

/**
 * Check (only on startup) whether the app is running in one of the supported browsers.
 *
 * Do not trigger this on mobile since mobile for now only has placeholder UI.
 */
function shouldShowBrowserWarning() {
  if (!(IS_BROWSER && IS_FIRST_PASS && isDesktop())) {
    return false
  }

  IS_FIRST_PASS = false
  const isSupportedBrowser = navigator.userAgent.match(
    /chrome|chromium|crios|edg|brave/i
  )

  return !isSupportedBrowser
}

export default function WalletPage() {
  const {
    wallets,
    metamaskStatus,
    namiStatus,
    eternlStatus,
    isWalletConnected,
  } = useMeldState()
  const [selectedWallet, setSelectedWallet] = useSelectedWallet()
  const linkMetamask = useLinkMetamask()
  const linkNami = useLinkNami()
  const linkEternl = useLinkEternl()
  const addCustomToken = useAddCustomToken()
  const vestingData = useVestingData(selectedWallet)
  const allAssets = useAllAssets()

  const showBrowserWarning = shouldShowBrowserWarning()

  return (
    <>
      <Layout>
        <WalletContainer
          wallets={wallets}
          selectedWallet={selectedWallet}
          onSelectedWallet={setSelectedWallet}
          linkMetamask={linkMetamask}
          linkNami={linkNami}
          linkEternl={linkEternl}
          metamaskStatus={metamaskStatus}
          namiStatus={namiStatus}
          eternlStatus={eternlStatus}
          isWalletConnected={isWalletConnected}
          vestingData={vestingData}
          allAssets={allAssets}
          addCustomToken={addCustomToken}
        />
      </Layout>

      <SupportedBrowsers showBrowserWarning={showBrowserWarning} />
    </>
  )
}
